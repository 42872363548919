import classNames from 'classnames'
import { FC, forwardRef, InputHTMLAttributes, LegacyRef, ReactNode, useState } from 'react'

import { Tooltip } from '../Tooltip'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  tooltip?: ReactNode
  labelColor?: string
  subText?: string
}

const TextInput = (
  { label, tooltip, labelColor, subText, ...otherProps }: IProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  const [isActive, setIsActive] = useState(false)

  const handleFocus = () => {
    setIsActive(true)
  }

  const handleBlur = () => {
    setIsActive(false)
  }
  return (
    <div className="flex flex-col gap-1.5">
      {label && (
        <div className="flex items-center gap-2">
          <div className="flex flex-col mb-1">
            <label
              className={classNames(
                'text-sm font-medium',
                isActive && labelColor ? `${labelColor} m-0` : 'text-grey500'
              )}>
              {label}
            </label>
            {subText && isActive && <p className="text-primary text-xs">{subText}</p>}
          </div>
          {tooltip && <Tooltip message={tooltip} />}
        </div>
      )}
      <input {...otherProps} ref={ref} onFocus={handleFocus} onBlur={handleBlur} />
    </div>
  )
}

export default forwardRef(TextInput)
