import '../styles.scss'

import { FC, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { goerli, useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import { ReactComponent as BookIcon } from '@/assets/images/icon-book.svg'
import { ReactComponent as DiscordIcon } from '@/assets/images/icon-discord.svg'
import { ReactComponent as ThreeDotIcon } from '@/assets/images/icon-dot-three.svg'
import { ReactComponent as HelperIcon } from '@/assets/images/icon-helper.svg'
import { ReactComponent as ListIcon } from '@/assets/images/icon-list-check.svg'
import Logo from '@/assets/images/logo.png'
import { ReactComponent as ChartIcon } from '@/assets/images/Poll.svg'
import { ButtonWalletConnect, ModalInsufficientBalance } from '@/components/app'
import ModalLegalPrivacy from '@/components/app/Modals/ModalLegalPrivacy'
import { Dropdown } from '@/components/shared'
import { TMenu } from '@/types'

import { ChainAlert } from './ChainAlert'
import NavItem from './NavItem'

const Topbar: FC = () => {
  const navigate = useNavigate()
  const { address } = useAccount()
  const { chain: activeChain, chains } = useNetwork()
  const { pathname } = useLocation()

  const [openInsufficientModal, setOpenInsufficientModal] = useState(false)

  const [openLegalModal, setOpenLegalModal] = useState(false)

  const options: TMenu[] = [
    // {
    //   id: 0,
    //   label: 'Monitoring',
    //   icon: <ChartIcon />,
    //   disabled: true,
    //   onClick: () => window.open('https://joinstakehouse.com/leaderboard', '_blank')
    // },
    // {
    //   id: 1,
    //   label: 'Help Center',
    //   icon: <HelperIcon />,
    //   onClick: () => window.open('https://help.joinstakehouse.com/en', '_blank')
    // },
    {
      id: 2,
      label: 'Docs',
      icon: <BookIcon />,
      onClick: () => window.open('https://docs.pon.network/', '_blank')
    },
    {
      id: 3,
      label: 'Discord',
      icon: <DiscordIcon />,
      onClick: () => window.open('https://discord.gg/s8N9ekQuuj', '_blank')
    },
    {
      id: 4,
      label: 'My Activity',
      icon: <ListIcon />,
      onClick: () => navigate('/activity')
    },
    {
      id: 5,
      label: 'Testnet Mode',
      toggle: true,
      onClick: () => handleToggle(activeChain?.id === goerli.id)
    }
  ]

  const handleToggle = (toggleState: boolean) => {
    if (toggleState === false) return window.open('https://goerli.pon.network/', '_self')
    return window.open('https://dapp.pon.network/', '_self')
  }

  const handleCloseLegalModal = () => {
    setOpenLegalModal(false)
  }

  const handleOpenModal = () => setOpenInsufficientModal(true)
  const handleCloseModal = () => setOpenInsufficientModal(false)

  return (
    <>
      <div className="topbar">
        <a onClick={() => navigate('/')} rel={'noopener noreferrer'}>
          <img src={Logo} alt="logo" width={50} />
        </a>

        <div className="topbar__navMenu">
          <Link to={'/'}>
            <NavItem active={!pathname.includes('more')}>Home</NavItem>
          </Link>
          <Link to={'/more'}>
            <NavItem active={pathname.includes('more')}>More</NavItem>
          </Link>
        </div>

        {address ? (
          <div className="flex items-center gap-3">
            <ButtonWalletConnect />
            <Dropdown options={options}>
              <div className="topbar__menu-btn">
                <ThreeDotIcon />
              </div>
            </Dropdown>
          </div>
        ) : (
          <div />
        )}
        <ModalInsufficientBalance open={openInsufficientModal} onClose={handleCloseModal} />
        <ModalLegalPrivacy open={openLegalModal} onClose={handleCloseLegalModal} />
      </div>
      <ChainAlert />
    </>
  )
}

export default Topbar
