import './styles.scss'

import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { css, styled } from 'twin.macro'

import ArrowLeftSVG from '@/assets/images/arrow-left.svg'
import { ReactComponent as ArrowTopRightIcon } from '@/assets/images/icon-arrow-top-right.svg'
import { ModalProposerOptOutAddressConfirm } from '@/components/app/Modals/ModalProposerOptOutAddressConfirm'
import { ModalProposerOptOutConfirm } from '@/components/app/Modals/ModalProposerOptOutConfirm'
import styles from '@/components/app/Modals/styles.module.scss'
import {
  Button,
  CompletedTxView,
  ErrorModal,
  LoadingModal,
  ModalDialog,
  TextInput,
  Tooltip,
  UploadKeyStoreFile
} from '@/components/shared'
import Switch from '@/components/shared/Switch'
import { config } from '@/constants/environment'
import { useNetworkBasedLinkFactories, useProposerMethods, useSDK } from '@/hooks'
import { KeystoreT } from '@/types'

interface PasswordValidationT {
  required?: string | undefined
  length?: string | undefined
}

export const AddKey: FC = () => {
  const navigate = useNavigate()

  const { ponSdk: sdk } = useSDK()
  const [keystoreObject, setKeystoreObject] = useState<KeystoreT>()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValidationErr, setPasswordValidationErr] = useState<PasswordValidationT>()

  const [failed, setFailed] = useState(false)
  const [error, setError] = useState<string>()
  const [txResult, setTxResult] = useState<any>()
  const [isToggled, setIsToggled] = useState(false)
  const [optOutModalOpen, setOptOutModalOpen] = useState(false)
  const [ecdsaAddressConfirmationModalOpen, setEcdsaAddressConfirmationModalOpen] = useState(false)
  const [ecdsaAddress, setEcdsaAddress] = useState('')

  const [signature, setSignature] = useState<string>()
  const [signatureError, setSignatureError] = useState<string>()
  const [feeRecipient, setFeeRecipient] = useState<string>('')

  const { makeEtherscanLink } = useNetworkBasedLinkFactories()
  const { register, setIsLoading, isLoading } = useProposerMethods()

  useEffect(() => {
    if (!confirmPassword) {
      return setPasswordValidationErr({ required: 'Password is required' })
    } else if (confirmPassword.length < 8) {
      return setPasswordValidationErr({ length: 'Your password must be 8 or more characters.' })
    } else {
      setPasswordValidationErr(undefined)
    }
  }, [confirmPassword])

  const handleSignatureChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSignature(e.target.value)
    if (!e.target.value) return
    try {
      const data = JSON.parse(e.target.value || '{}')
      const { registrationSignature, ecdsaSig, blsPublicKey, payoutRecipient, representative } =
        data
      setFeeRecipient(payoutRecipient)
      let items: string[] = []
      if (!registrationSignature) {
        items = [...items, 'registrationSignature']
      }
      if (!ecdsaSig) {
        items = [...items, 'ecdsaSig']
      }
      if (!blsPublicKey) {
        items = [...items, 'blsPublicKey']
      }
      if (!payoutRecipient) {
        items = [...items, 'payoutRecipient']
      }
      if (!representative) {
        items = [...items, 'representative']
      }
      const isPlural = items.length > 1

      if (items.length)
        setSignatureError(`${items.join(', ')} ${isPlural ? 'are' : 'is'} required.`)
      else setSignatureError('')
    } catch (error) {
      setSignatureError('Invalid JSON object.')
    }
  }

  const handleGoNextStep = (keystoreObject: KeystoreT) => {
    setKeystoreObject(keystoreObject)
  }

  const handleRegister = async () => {
    try {
      const data = JSON.parse(signature || '{}')

      const { registrationSignature, ecdsaSig, blsPublicKey, payoutRecipient, representative } =
        data

      let txResult: any
      if (isToggled) {
        txResult = await sdk?.proposerRegistry.registerProposerWithSignature(
          blsPublicKey,
          payoutRecipient,
          registrationSignature,
          ecdsaSig,
          false
        )
      } else {
        txResult = await sdk?.proposerRegistry.registerProposerOutsidePayoutPoolUsingSignature(
          blsPublicKey,
          payoutRecipient,
          payoutRecipient,
          registrationSignature,
          ecdsaSig,
          false
        )
      }

      setTimeout(() => {
        setTxResult(txResult)
      }, 500)
    } catch (err: any) {
      console.log(err, err.message)
      setIsLoading(false)
      setTimeout(() => {
        if ('reason' in err) setError(err.reason[0].toUpperCase() + err.reason.substr(1))
        else setError(err.message)
        setFailed(true)
      }, 500)
    }
  }

  const handleCloseSuccessModal = () => {
    setTxResult(undefined)
    navigate('/')
  }

  return (
    <div className="flex justify-center items-center flex-col gap-4">
      <Box>
        <div className="flex items-center mb-4">
          <img
            src={ArrowLeftSVG}
            className="w-6 h-6"
            onClick={() => navigate('/manage/proposer')}
          />
          <Label>Add New Validator Key</Label>
        </div>
        <div className="p-4 bg-grey900 rounded-lg flex flex-col gap-4">
          <div className="text-base font-semibold flex gap-2 text-primary mt-2">
            Register your validator key
            <Tooltip message="This will connect your validator's BLS key to the PoN Relay as a Proposer. Your BLS key is not stored. This step is to ensure you are the owner." />
          </div>
          <div className="flex flex-col gap-2 font-medium text-sm text-white">
            <div>Use your signature</div>
            <TextInput
              className={styles.input}
              value={signature}
              onChange={handleSignatureChange}
            />
            {signatureError && <div className="text-red">{signatureError}</div>}
            <div
              className="flex gap-1 cursor-pointer items-center text-grey600 justify-end"
              onClick={() =>
                window.open('https://docs.pon.network/pon/how-to-gen-proposer-signature', '_blank')
              }>
              How To <ArrowTopRightIcon />
            </div>
          </div>
          {!isToggled && (
            <TextInput
              label="Fee Recipient Address"
              className={styles.input}
              disabled
              value={feeRecipient}
              onChange={(e) => setFeeRecipient(e.target.value)}
            />
          )}
          <div className="flex flex-row items-center gap-2 justify-start text-left w-full">
            <Switch
              isToggled={isToggled}
              onToggle={() => {
                setIsToggled(!isToggled)
                // setOptOutModalOpen(true)
              }}
            />
            <OptOutText isToggled={isToggled}>Opt into Automated Payouts</OptOutText>
            <Tooltip message="Opting out payout pool will exclude you from 7 days automated payouts." />
          </div>
          <Button size="lg" className="w-full" onClick={handleRegister}>
            Confirm
          </Button>
        </div>
      </Box>
      <Comment>
        By becoming a Proposer in the PoN Relay, you will earn ETH by outsourcing your blockspace.
      </Comment>
      <ModalProposerOptOutConfirm
        isToggled={isToggled}
        optOutModalOpen={optOutModalOpen}
        setIsToggled={setIsToggled}
        setOptOutModalOpen={setOptOutModalOpen}
      />
      {/* <ModalProposerOptOutAddressConfirm
        ecdsaAddress={ecdsaAddress}
        ecdsaAddressConfirmationModalOpen={ecdsaAddressConfirmationModalOpen}
        setEcdsaAddressConfirmationModalOpen={setEcdsaAddressConfirmationModalOpen}
        handleApprove={handleApprove}
      /> */}
      <LoadingModal open={isLoading} title="Confirmation Pending" onClose={() => {}} />
      <ErrorModal
        open={failed}
        onClose={() => setFailed(false)}
        title="Register Failed"
        message={error}
        actionButtonContent="Try Again"
        onAction={() => setFailed(false)}
      />
      <ModalDialog open={!!txResult} onClose={() => setTxResult(undefined)}>
        <CompletedTxView
          goToContent="Home"
          title="Success"
          txLink={makeEtherscanLink(txResult?.hash)}
          onGoToClick={handleCloseSuccessModal}
          message={
            <div className="flex flex-col items-center">
              <span className="text-sm text-grey300">{`You have successfully connected a new BLS key to the PoN Relay.`}</span>
              {/* {config.PON_RELAY_URL && (
                <span className=" mt-4 px-8 py-3 rounded-lg text-xs font-medium text-primary500 bg-greenishGrey border border-solid border-innerBorder flex justify-between break-all">
                  Make sure your validator is configured with <br /> MEV-Boost and configured with
                  the relayer URL: <br /> {config.PON_RELAY_URL}
                </span>
              )} */}
            </div>
          }
        />
      </ModalDialog>
    </div>
  )
}

const Box = styled.div`
  ${tw`w-full bg-grey850 mt-10 max-w-lg p-4 rounded-2xl flex flex-col gap-4`}
`
const Label = styled.div`
  ${tw`text-white font-semibold text-center w-full`}
  font-size: 32px;
`
const Comment = tw.div`bg-grey900 text-grey700 text-sm font-medium max-w-lg px-8 py-6 rounded-2xl`

const OptOutText = styled.span<{ isToggled: boolean }>`
  ${({ isToggled }) =>
    isToggled
      ? css(tw`text-white`)
      : css`
          color: #3a3a3c;
        `}
`
