import { Dialog } from '@headlessui/react'
import { FC } from 'react'

import { ReactComponent as CloseCircleIcon } from '@/assets/images/close-circle.svg'
import { ReactComponent as RedAlertIcon } from '@/assets/images/icon-alert-red.svg'
import styles from '@/components/app/Modals/styles.module.scss'
import { Button, Modal } from '@/components/shared'

interface IProps {
  isToggled: boolean
  optOutModalOpen: boolean
  setIsToggled: (newIsToggled: boolean) => void
  setOptOutModalOpen: (newOptOutModalOpen: boolean) => void
}

export const ModalProposerOptOutConfirm: FC<IProps> = ({
  isToggled,
  optOutModalOpen,
  setIsToggled,
  setOptOutModalOpen
}) => {
  return (
    <Modal
      open={isToggled && optOutModalOpen}
      onClose={() => {
        setOptOutModalOpen(false)
        setIsToggled(false)
      }}>
      <Dialog.Panel className={`${styles.modalLayoutBig}`}>
        <div
          className="absolute top-3 right-3 cursor-pointer"
          onClick={() => {
            setOptOutModalOpen(false)
            setIsToggled(false)
          }}>
          <CloseCircleIcon />
        </div>
        <div className={styles.confirmDepositFailed}>
          <RedAlertIcon />
          <p className={styles.modalTitle}>Are you sure?</p>
          <p className={styles.confirmDepositDesc}>
            Stay in the payout pool to enjoy 7 days of automated payouts!
          </p>
          <p className={styles.confirmDepositDesc}>
            Opting out means you&apos;ll only receive MEV payouts for your blocks.
          </p>
          <div className="w-11/12 flex justify-between">
            <Button
              variant="primary"
              className="w-full mx-2"
              onClick={() => {
                setOptOutModalOpen(false)
                setIsToggled(false)
              }}>
              Keep My 7-day Payouts
            </Button>
            <Button
              variant="secondary"
              className="w-full mx-2"
              onClick={() => {
                setOptOutModalOpen(false)
                setIsToggled(true)
              }}>
              Opt Out
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  )
}
