import './styles.scss'

import { Menu, Transition } from '@headlessui/react'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { goerli, useNetwork, useSwitchNetwork } from 'wagmi'

import { TMenu } from '@/types'

import Switch from '../Switch'

interface IProps {
  options: TMenu[]
}

const Dropdown: FC<PropsWithChildren<IProps>> = ({ children, options }) => {
  const { chain: activeChain, chains } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()
  const [isToggled, setIsToggled] = useState(false)

  function handleOptionClick(option: TMenu): void {
    if (option.disabled) return

    if (option.onClick) {
      option.onClick()
    }
  }

  useEffect(() => {
    if (activeChain && switchNetwork) {
      setIsToggled(activeChain?.id === goerli.id)
    }
  }, [activeChain, switchNetwork])

  const handleToggle = (toggleState: boolean) => {
    setIsToggled(toggleState)
    if (toggleState === true) return window.open('https://goerli.pon.network/', '_self')
    return window.open('https://dapp.pon.network/', '_self')
  }

  return (
    <Menu as="div" className="relative" style={{ height: 38 }}>
      <Menu.Button>{children}</Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <Menu.Items className="menu__popup">
          {options.map((option) => (
            <Menu.Item key={option.id} disabled={option.disabled}>
              {({ active, disabled }) => (
                <div
                  className={`${active ? 'menu__item--selected' : 'menu__item'}
                    ${disabled ? 'disabled' : ''}`}
                  onClick={() => handleOptionClick(option)}>
                  {option.icon}
                  <span>{option.label}</span>
                  {option.toggle && (
                    <>
                      <p className="text-white">{isToggled ? 'On' : 'Off'}</p>
                      <Switch isToggled={isToggled} onToggle={() => handleToggle(!isToggled)} />
                    </>
                  )}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
