import './styles.scss'

import classNames from 'classnames'
import { FC } from 'react'

interface SwitchProps {
  isToggled: boolean
  onToggle: () => void
}

const Switch: FC<SwitchProps> = ({ isToggled, onToggle }) => {
  return (
    <label className={classNames('switch', isToggled ? 'bg-primary500' : 'bg-toggleBG')}>
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="slider round"></span>
    </label>
  )
}

export default Switch
